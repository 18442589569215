import React from 'react';
import {Room,Member} from './Inc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
	room:Room,
	members:Member[], 
}

const MemberList = (props:Props)=> {
	if( props.members.length===0 ){
		return (null);
	}
    return (
		<div id="play_room_members" className="memberList">
			<h2>残り物の名簿</h2>
			<ol>
		{props.members.map((member:Member,index:number)=>{
			const key = "member"+index;
			let cl = "";
			if( member.VanishedDay>0 ){
				cl = "dead"
			}
			return (
				<li key={key} className={cl}>
					{props.room.Names[member.CharaId-1]}
					{member.VanishedDay>0 &&
					<FontAwesomeIcon icon="ghost" className="ghost"/>
					}
				</li>
			)
		})}
			</ol>
        </div>
    );
}

export default MemberList;
