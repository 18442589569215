import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
	itemId: string,
	itemName?: string,
}

const ItemSign = (props:Props)=> {
	let itemIcon:"hand-paper"|"fist-raised"|"hand-scissors" = "hand-paper";
	let itemClass = "paa";
	if( props.itemId==="1" ){
		itemIcon = "fist-raised";
		itemClass = "guu";	
	}
	if( props.itemId==="2" ){
		itemIcon = "hand-scissors";
		itemClass = "choki";	
	}
	if( props.itemId==="0" ){
		return (null);
	}

	return (
		<React.Fragment>
			<FontAwesomeIcon icon={itemIcon} className={itemClass} style={{marginRight:"0.25em"}}/>
			{props.itemName &&
				<React.Fragment>{props.itemName}</React.Fragment>
			}
		</React.Fragment>
	)
}

export default ItemSign;