import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';

interface Props {
	isArchive:boolean,
	update(value:boolean):void,
}

const SortUp = (props:Props)=> {
    const toggle = ()=>{
		props.update(!props.isArchive);
	}
	
	let icon: "sort-numeric-up-alt" | "sort-numeric-down" = "sort-numeric-up-alt";
	let cl: "primary"|"secondary" = "primary";
	let title = "古い順に表示";
	if( props.isArchive ){
		icon = "sort-numeric-down";
		cl = "secondary";
		title = "新しい順に表示";
	}

    return (
        <div style={{position:"fixed",right:"4em",bottom:"0.5em"}}>
            <Tooltip title={title}><Fab color={cl} size="small" onClick={toggle}><FontAwesomeIcon icon={icon} style={{fontSize:"140%"}}/></Fab></Tooltip>
        </div>
    )
}

export default SortUp;