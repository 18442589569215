export const CookieHashName = "ticketa19";
export const getCookieOptions = (domain:string | undefined)=>{
	//console.log("domain",domain);
	const expire = new Date();
	expire.setDate(expire.getDate() + 20);
	const options = {
		path: "/",
		expires: expire,
		maxAge: 86400*20,
		domain: domain,
		secure: false,
		httpOnly: false,
		sameSite: true,
	}
	return options;
};

export interface Dataset {
	id: string,
	label: string,	
}
export interface Boolset {
	id: boolean,
	label: string,	
}
export interface PagerParam {
	Start:number,
	End:number,
	MinPage:number,
	MaxPage:number,
	Current:number,
	Count:number,
}
export interface Profile {
	name: string,
	dateKeys: string[],
	version: number,
	secret: string,
	firstDateKey: string,
	lastDateKey: string,
	publicKey: string,
}
export interface Info {
	Title: string,
	Text: string,
	Date: string,
	Deleted: boolean,
}

export interface Room {
	Id: string,
	Title: string,
	IntervalId: string,
	CharaSetId: string,
	CharaSetTitle: string,
	TopCharaId: number,
	Names: string[],
	Day: number,
	Loop: number,
	NumMembers: number,
	GhostItems: string,
	NextTime: string,
	Accepting: boolean,
	Listing: boolean,
	OpenedAt: string,
	UserId: string,
	Status: string,
	Deleted: boolean,
	CreatedAt: string,
	UpdatedAt: string,
}
export interface Member {
	CharaId: number,
	PlaceId: string,
	SelectId: string,
	Checked: boolean,
	VanishedDay: number,
	ItemId: string,
	ItemName: string,
	WinnerId: number,
}
export interface PrivateData {
	CharaId: number,
	ItemId: string,
	ItemName: string,
	History: Member[],
	CheckedDay: number,
	IsPlay: boolean,
}
export interface CharaSet {
	Id: string,
	UserId: string,
	Title: string,
	Names: string[],
	Permission: string,
	Deleted: boolean,
	CreatedAt: string,
	UpdatedAt: string,
}
export interface Face {
	Name: string,
}
export interface Talk {
	Id: number,
	Day: number,
	TypeId: string,
	CharaId: number,
	Serial: number,
	Message: string,
	Date: string,
}
export interface RoomInputErrors {
	Title: string,
	CharaSetId: string,
}
export class Inc {
	static readonly errorText = "青字";
	static readonly numFaces = 20;
	static readonly numSystemFaces = 2;
	static readonly numMembersCapacity = 9;
	static readonly ROOM_STATUS_WAITING    = "0"
	static readonly ROOM_STATUS_INSERVICE = "1"
	static readonly ROOM_STATUS_EPILOGUE   = "2"
	static readonly ROOM_STATUS_CLOSED     = "9"
	static readonly ROOM_STATUS_CANCELED   = "8"
	static readonly numTrapAvailable = 3;
	static readonly numMessageMax = 200;
	static readonly placeNames = ["ホール","紳士淑女の間","偽写像の間","牝馬ララの間","採血官の間","闘士控えの間","印章の間","氷時計の間","暗闇の間","光の間"];
	static readonly charaSetPermissions:Dataset[] = [
		{
			id:"0",
			label:"準備中（利用不可）",
		},
		{
			id:"1",
			label:"	自分のルームでだけ利用可",
		},
		{
			id:"2",
			label:"すべてのルームで利用可",
		}

	]
	static readonly dayIntervals:Dataset[] = [
		{
			id:"1",
			label:"24時間",
		},
		{
			id:"2",
			label:"12時間",
		},
		{
			id:"3",
			label:"10分",
		},
	]
	static readonly defaultPagerParam = ()=>{
		let param:PagerParam = {
			Start:0,
			End:0,
			MinPage:0,
			MaxPage:0,
			Current:1,
			Count:0,		
		}
		return param;
	}
	static readonly defaultProfile = ()=>{
		let profile:Profile = {
			name: "",
			dateKeys: [],
			version: 0,
			firstDateKey: "",
			lastDateKey: "",
			secret: "0",
			publicKey: "",
		};
		return profile;
	}
	static readonly defaultRoom = ()=>{
		let room:Room = {
			Id: "",
			Title: "",
			Names: [] as string[],
			CharaSetId: "",
			CharaSetTitle: "",
			TopCharaId: 0,
			IntervalId: "",
			NumMembers: 0,
			GhostItems: "",
			NextTime: "",
			Day: 0,
			Loop: 0,
			Accepting: false,
			Listing: false,
			UserId: "",
			Status: "",
			Deleted: false,
			OpenedAt: "",
			CreatedAt: "",
			UpdatedAt: "",
		};
		return room;
	}
	static readonly defaultPrivateData = ()=>{
		let data:PrivateData = {
			CharaId: 0,
			ItemId: "0",
			ItemName: "",
			History: [] as Member[],
			CheckedDay: 0,
			IsPlay: false,
		};
		return data;
	}
	static readonly defaultCharaSet = ()=>{
		let charaSet:CharaSet = {
			Id: "",
			UserId: "",
			Title: "",
			Names: [] as string[],
			Permission: "0",
			Deleted: false,
			CreatedAt: "",
			UpdatedAt: "",
		};
		return charaSet; 
	}
	static readonly defaultRoomInputErrors = ()=>{
		let errors:RoomInputErrors = {
			Title: "",
			CharaSetId: "",
		}
		return errors;
	}
}
