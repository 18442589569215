import React,{useMemo} from 'react';
import ImgFace from './ImgFace';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
	isArchive:boolean,
}

const TalkNight = (props:Props)=> {
	const text = useMemo( ()=>{
		let talks = [];
		talks[0] = (
				<div className="sysTalk" key="t0">
					<span className="type">おやすみ</span>
					{props.talk.Day===0 &&
					<span className="detail">
						ハコブネが動き出しました。
					</span>
					}
					{props.talk.Day>0 &&
					<span className="detail">
						残り物たちはそれぞれの場所で眠りにつきました。
					</span>
					}
					<span className="time">{Tools.toTime(props.talk.Date)}</span>
				</div>
			);
		talks[1] = (
				<div className="talk talkPublic" key="t1">
					<div className="serial">
						<span className="date">{Tools.toDate(props.talk.Date)}</span>
					</div>
					<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
					<div className="text">
						<h3>{props.room.Names[Inc.numFaces]}</h3>
						<div>
							夜が来ます…<br/>
							どこまでも逃げるように眠りなさい、急いで…<br/>
						</div>
					</div>
				</div>
			);
		if( props.isArchive ){
			talks.reverse();
		}
	
		return talks;
	},[props.talk,props.isArchive])

    return (
		<React.Fragment>
			{text}
		</React.Fragment>
	);
}

export default TalkNight;
