import React,{useMemo} from 'react';
import ImgFace from './ImgFace';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
	isArchive:boolean,
}

const TalkClosed = (props:Props)=> {
	const text = useMemo( ()=>{
		let talks = [];
		talks[0] = (
			<div className="sysTalk" key="t0">
				<span className="type">終幕</span>
				<span className="detail">お疲れ様でした。気を付けてお帰り下さい</span>
				<span className="time">{Tools.toTime(props.talk.Date)}</span>
			</div>
		);
		talks[1] = (
			<div className="talk talkPublic" key="t1">
				<div className="serial">
					<span className="date">{Tools.toDate(props.talk.Date)}</span>
				</div>
				<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+2}/></div>
				<div className="text">
					<h3>{props.room.Names[Inc.numFaces+1]}</h3>
					<div>
						「おじい様！おじい様！ちょっと星をどけてくれませんこと？」
					</div>
				</div>
			</div>
		);
		if( props.isArchive ){
			talks.reverse();
		}
	
		return talks;
	},[props.talk,props.isArchive])

    return (
		<React.Fragment>
			{text}
		</React.Fragment>
	);
}

export default TalkClosed;
