import React,{useState} from 'react';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
//import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ModalHeader from './ModalHeader';
import {Tools} from './Tools';
import HelpIcon from '@material-ui/icons/Help';
import Fab from '@material-ui/core/Fab';

const Help = ()=> {
	const [isOpen,setIsOpen]=useState(false);
    const open = ()=>{
		setIsOpen(true);
    }
    const close = ()=>{
		setIsOpen(false);
    }
    return (
		<React.Fragment>
			<div style={{position:"fixed",left:"0.5em",bottom:"0.5em"}}>
				<Tooltip title="遊び方">
					<Fab color="primary" size="small" onClick={open}><HelpIcon/></Fab>
				</Tooltip>
			</div>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={isOpen}
				onClose={close}
			>
				<div id="faces_input" className="modal">
					<ModalHeader close={close}/>
					<div className="modalRoot" style={Tools.getModalRootStyle()}>
						<Paper className="paper modalPaper">
							<h2>ヘルプ・遊び方</h2>
							<div className="help">
								<h3>どういうゲーム？
								</h3>
								<div className="text">
									<p>同じ場所で寝た相手とジャンケンして負けたらアウト。自分の手は毎回自動的に決まるので、寝床を慎重に選びつつ最後まで生き残るのがゲームの目的です。参加者同士で会話をしながら（時には嘘もついて）お互いの思惑を探ってください。</p>
								</div>
								<h3>手相</h3>
								<div className="text">
									<p>朝一番にそれぞれの手相が発表されます。手相の種類は無数にありますが、最初の文字が「ぐ」なら今日のあなたの手はグー、「ちょ」ならチョキ、「ぱ」ならパーです。</p>
									<div className="hint">
										<p>・手相は本人だけにこっそり知らされます。</p>
										<p>・手相は毎日変わりますが２日連続で同じ手になることはありません。例えば前日がグーならば、今日はパーかチョキのどちらかです。</p>
										<p>・最初の（１日目の）手相だけはグーチョキパーがだいたい同数になっています。２日目以降はその限りではありません。</p>
										<p>・朝が来るタイミング（１日の長さ）は参加するハコブネにより異なります。最短で10分、最長で24時間です。1日の長さがゲームの途中で変わることはありません。</p>
									</div>
								</div>
								<h3>寝床の選択</h3>
								<div className="text">
									<p>手相が発表されたら、その日が終わるまでに寝床を選択してください。</p>
									<div className="hint">
										<p>・寝床の選択は一日一回だけです。選択後に変更することはできません。</p>
										<p>・寝床の選択をしなかった場合は、自動的に「ホール」を選んだことになります。</p>
										<p>・あなたがどこを選んだかはもちろん、選択をしたかどうかもほかの参加者にはわかりません。</p>
									</div>
								</div>
								<h3>ジャンケンの勝敗</h3>
								<div className="text">
									<p>１日が終わったら（次の日になったら）同じ寝床にいる相手と手を比べて、負けた方が消滅してゴーストになります。</p>
									<div className="hint">
										<p>・勝敗の結果はそれぞれの寝床や手と合わせて全員に公開されます。</p>
										<p>・あいこの場合は何も起きませんが、グーチョキパーの全部がそろったあいこは大銀河の三つ巴効果によりその寝床にいる全員が消滅してゴーストになります。（ホールでのみ発生します。）</p>
									</div>
								</div>
								<h3>ゴースト</h3>
								<div className="text">
									<p>ジャンケンで負けるとその日からゴーストになります。ゴーストには寝床の選択がなく、ジャンケンもできません。</p>
									<div className="hint">
										<p>・ゴーストはそれまでと同様に会話を眺めることができますが、ゴーストの言っていることはゴースト以外の参加者には届きません。</p>
										<p>・ゴーストは毎朝の手相の発表を盗み聴きすることができます。ただし、誰がどの手相なのかまではわかりません。（人物名の部分が伏字で表示順もランダムになります。）</p>
										<p>・ゴーストは寝床の選択を盗み見ることができます。ただし、誰がどこを選んだかまではわかりません。（寝床の部分が伏字になります。）</p>
									</div>
								</div>
								<h3>見えざる手</h3>
								<div className="text">
									<p>ゴーストが３人以上になると「見えざる手」が使用できるようになります。見えざる手はゴーストによるジャンケンです。一人きりの寝床に強制的に出現して、ジャンケン勝負をしかけます。</p>
									<div className="hint">
										<p>・見えざる手はゴーストたちの投票によりグーチョキパーのいずれか一つが選ばれます。最多得票かつ全投票数の過半数以上で決定です。無投票は票数に含みません。</p>
										<p>・票が割れてどれも過半数以上にならない場合は見えざる手は使用されません。</p>
										<p>・一人きりの寝床が複数ある場合、見えざる手はそのすべてに出現します。</p>
										<p>・通常のジャンケンに負けて一人消滅した（一人残った）寝床には出現しません。</p>
										<p>・見えざる手の出現の有無や勝敗は、通常のジャンケンの結果と同様に公表されます。</p>
										<p>・見えざる手の投票は一日一回だけです。投票後に変更することはできません。</p>
									</div>
								</div>
								<h3>ジャーナル</h3>
								<div className="text">
									<p>見えざる手の投票速報が1日2回、全参加者に公表されます。</p>
									<div className="hint">
										<p>・公表時点で最多得票となっている手の発表です。何票入っているかは知らされません。</p>
										<p>・まったく投票されていない場合と、票が割れている（最多得票の手が１つにならない）場合は、「亡霊たちは息を潜めている」と表示されます。</p>
									</div>
								</div>
								<h3>寝床の定員</h3>
								<div className="text">
									<p>ホール以外の寝床は定員2名で先着順です。</p>
									<div className="hint">
										<p>・定員を超えているかどうかは選択した時点では分かりません。</p>
										<p>・定員を超えた場合はホールで寝ることになります。</p>
										<p>・ホールには定員がありません。</p>
									</div>
								</div>
								<h3>寝床の立入禁止</h3>
								<div className="text">
									<p>誰にも選ばれなかった寝床は立入禁止となり、以後選択できなくなります。</p>
									<p>ゴーストの見えざる手に負けて誰もいなくなってしまった場合は立入禁止にはなりません。</p>
									<p>ホールは誰もいなくなっても立入禁止になりません。</p>
								</div>

								<h3>勝利条件</h3>
								<div className="text">
									<p>・ゴーストにならずに生き残っている参加者が１人だけになったら、その参加者の勝利となります。</p>
									<p>・全員がゴーストになったら、ゴーストたちの勝利となります。</p>
								</div>
								<h3>エピローグ</h3>
								<div className="text">
									<p>勝敗の決着後、これまでのすべての発言と行動が公開されます。</p>
									<div className="hint">
										<p>・自分にしか見えなかった発言なども公開されますので、見られて困るような内容は書かないように気をつけてください。</p>
										<p>・エピローグは24時間経過すると終了します。</p>
									</div>
								</div>
								<h3>ハコブネの作成とインターバル</h3>
								<div className="text">
									<p>ゲーム（ハコブネ）の定員は6～9人です。参加したいハコブネがない場合は「ハコブネ作成」でハコブネを追加して下さい。</p>
									<div className="hint">
										<p>・ハコブネ作成では進行ペースを「10分」「12時間」「24時間」から選ぶことができます。これはゲーム内での1日の長さです。例えば「10分」を選ぶと寝床の選択を10分に1回することになり、かなり忙しい進行になります。</p>
										<p>・最初の朝までに参加者が必要最低人数（6人）に達しなかったハコブネは強制終了になります。この場合はエピローグはありません。</p>
										<p>・進行ペース「10分」を選んだ場合、参加者の募集期間が最大1時間まで延長されます。</p>
									</div>
								</div>
								<h3>その他</h3>
								<div className="text">
									<p>右下のボタンでログの表示順を古い順表示にすることができます。</p>
									<div className="hint">
										<p>・古い順表示にしている時はメッセージの書き込みができないようになっています。新しい順に戻してから書いて下さい。</p>
										<p>・古い順表示にしている時はログの自動更新も行われません。いつの間にか次の日になってるなんてこともありえますのでご注意ください。</p>
									</div>
								</div>
								<h3>著作権等・注意事項</h3>
								<div className="text">
									<p>このコンテンツの著作権はレトロインクが有します。ただし、キャラクターのイラストの著作権はナカニシミエが有します。</p>
									<p>レトロインクは、プレイヤーがこのコンテンツに入力したテキストを、このコンテンツ内において自由に使用する権利を有します。</p>
									<p>このコンテンツは動作確認および仕様検討のためのパイロット版のバージョン２です。予告なく終了する場合もあります。</p>
								</div>
								<div className="history">
									<ul>
										<li>2020.08.14 公開</li>
										<li>2020.08.18 ヘルプの間違いを修正（ハコブネの作成とインターバル：×6時間→〇12時間）</li>
										<li>2020.08.18 ヘルプに加筆（ジャーナル）</li>
									</ul>
								</div>
							</div>
						</Paper>
					</div>
				</div>
			</Modal>
		</React.Fragment>
    )
}

export default Help;