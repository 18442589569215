import React from 'react';
import {Inc,Room,Talk} from './Inc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ItemSign from './ItemSign';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
}

const TalkItem = (props:Props)=> {
	const [itemId,itemName] = props.talk.Message.split("_");
	let name = "";
	if(props.talk.CharaId>0){
		name = props.room.Names[props.talk.CharaId-1];
	} else {
		name = "■■■";
	}
	return (
		<div className="sysTalk">
			<span className="type">手相</span>
			<span className="detail"><ItemSign itemId={itemId}/> 今日の{name}の手相は <b>{itemName}</b> です</span>
			<span className="time">{Tools.toTime(props.talk.Date)}</span>
		</div>
	);
}

export default TalkItem;
