import React,{useEffect,useState} from 'react';
import {Redirect} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
//import Tooltip from '@material-ui/core/Tooltip';
//import AutorenewIcon from '@material-ui/icons/Autorenew';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalHeader from './ModalHeader';
import ColInput from './ColInput';
//import ClimbUp from './ClimbUp';
import Loading from './Loading';
import ImgFace from './ImgFace';
//import store from './store';
import Toast from './Toast';
import {UserContainer} from './UserContainer';
import {Inc,CharaSet} from './Inc';
import {Tools} from './Tools';
import Yesno from './Yesno';
import Ajax from './Ajax';
//import store from './store';

interface Props {
	isOpen: boolean,
	charaSet: CharaSet,
	update(title:string,permission:string,names:string[]):void,
	delete():void,
	close():void,
}

const FacesInput = (props:Props)=> {
    const [states,setStates] = useState({
		title:"",
		permission: "0",
		names: [] as string[],
		files: [] as File[],
		datas: [] as string[],
		updateKey: "",
		yesno: "",
        isLoading:true,
	})
	const [seeId,setSeeId] = useState("");
	const [toast,setToast] = useState("");
	const userState = UserContainer.useContainer();

	const closeToast = ()=>{
		setToast("");
	}

	const changeParam = (name:string,value:string)=>{
		setStates({...states,[name]:value});
	}

	const changeName = (name:string,value:string)=>{
		const index = parseInt(name.substring(4));

		let names:string[] = [];
		if(index>0){
			names = states.names.slice(0,index).concat(value).concat(states.names.slice(index+1));
		} else {
			names = [value].concat(states.names.slice(1));
		}
		setStates({...states,names:names})
	}

	const change = async (e: React.ChangeEvent<HTMLInputElement>)=>{
        const target = e.target;
        if( !target || !target.files || !target.files.item(0) || !e.target.dataset.index ){
            return;
		}
		const index = parseInt(e.target.dataset.index);

		const file = target.files.item(0);
		if( file ){
			var reader = new FileReader();
			var img = new Image();
			reader.onload = async (e:any) => {
				const data = e.target.result;
				img.onload = async ()=>{
					const w = img.naturalWidth;
					const h = img.naturalHeight;
					if( w===100 && h===133 ){
						try {
							if( !userState.user ){
								return;
							}
							//const res = await Ajax.setFace(data,userState.user.uid,index);
							await Ajax.setFace(data,userState.user.uid,index);
							//console.log(res);
							let datas = [] as string[];
							if(index>0){
								datas = states.datas.slice(0,index).concat(data).concat(states.datas.slice(index+1));
							} else {
								datas = [data].concat(states.datas.slice(1));
							}
							setStates({...states,datas:datas});
						} catch(error){
							console.log(error);
						}
					}
				}
				img.src = data;
			};
			reader.readAsDataURL(file);
		} else {
			//setStates({...states,photoData:""});
		}
	}
	const validate = async ()=>{
		//const flag = await Ajax.isSameCharaset(states.title,props.charaSet.Id);
		if( states.title==="" ){
			setToast("名称は省略できません");
			return;
		}
		//if( flag ){
		//	setToast("この名称は使用されています");
		//	return;
		//}
		props.update(states.title,states.permission,states.names);
	}

	const confirm = ()=>{
		setStates({...states,yesno:"削除してよろしいですか？"});
	}

	const yes = async ()=>{
		props.delete();
	}

	const no = ()=>{
		setStates({...states,yesno:""});
	}

    useEffect( ()=>{
        if( !userState.ready || !userState.user ){
			return;
		}
		Ajax.clearFace(userState.user.uid).then((res)=>{
			let names:string[] = [];
			let datas:string[] = [];
			for( let i=0; i<Inc.numFaces+Inc.numSystemFaces; i++ ){
				if(props.charaSet.Names[i]){
					names.push(props.charaSet.Names[i]);
				} else {
					names.push("");
				}
				datas.push("");
			}
			setStates({...states,title:props.charaSet.Title,permission:props.charaSet.Permission,names:names,datas:datas,files:[],updateKey:Tools.getUniqueId(),yesno:"",isLoading:false});	
		})
	},[userState.ready,userState.user,props.isOpen]);

    if( !userState.ready ){
        return (null);
    }

	if( seeId!=="" ){
		const to = "/room/detail/"+seeId;
		return (
			<Redirect to={to}/>
		)
	}

	if( !props.charaSet ){
		return (null);
	}

    return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={props.isOpen}
			onClose={props.close}
		>
			<div id="faces_input" className="modal">
				<ModalHeader close={props.close}/>
				<div className="modalRoot" style={Tools.getModalRootStyle()}>
					<Paper className="paper modalPaper">
						<h2>
							オリジナルセット作成
							<div className="btns">
								<Button variant="contained" color="primary" onClick={validate}>投稿する</Button>
								{props.charaSet.Id!=="" &&
								<Button variant="contained" color="primary" onClick={confirm}>削除する</Button>
								}
							</div>
						</h2>
						<Grid container spacing={2} className="faces">
							<Grid item xs={2} className="formName">
								キャラセット名称
							</Grid>
							<Grid item xs={10}>
								<ColInput type="text" id="title" value={states.title} name="キャラセット名称" placeholder="キャラセット名称" change={changeParam} noName={true} dense={true}/>
							</Grid>
							<Grid item xs={2} className="formName">
								利用許可
							</Grid>
							<Grid item xs={10}>
								<ColInput type="radio" list={Inc.charaSetPermissions} id="permission" value={states.permission} name="利用許可" change={changeParam} noName={true} dense={true}/>
							</Grid>
							<Grid item xs={12} className="formName">
								プレイヤーキャラ
							</Grid>
						{states.names.map((name:string,index:number)=>{
							const key = "face"+index;
							const nameId = "name"+index;
							let holder = "No."+(index+1)+"の名前";
							if( index>=Inc.numFaces ){
								holder = "進行役" + String(index-19);
								if( index===Inc.numFaces ){
									holder += "（ソニア）";
								} else {
									holder += "（アウロラ）";
								}
							}
							return (
								<React.Fragment key={key}>
								{index===Inc.numFaces && 
									<Grid item xs={12} className="formName">
										システムキャラ（進行役）
									</Grid>
								}
								<Grid item xs={3} style={{paddingBottom:"3em"}}>
									<div className="imgFace">
										{states.datas[index] !=="" ? (
											<img src={states.datas[index]}/>
										):(
											<ImgFace code={props.charaSet.Id} charaId={index+1} updateKey={states.updateKey}/>
										)}										
									</div>
									<FormControl>
										<ColInput type="text" id={nameId} value={states.names[index]} placeholder={holder} name={nameId} change={changeName} noName={true} dense={true}/>
									</FormControl>
									<FormControl fullWidth={true}>
										<input type="file" accept="image/*" onChange={change} data-index={index}/>
									</FormControl>
								</Grid>
								</React.Fragment>
							)
						})}
						</Grid>
						<div className="notice">
							<h3>注意事項</h3>
						</div>
					</Paper>
					<Yesno yes={yes} no={no} mes={states.yesno} isOpen={states.yesno!==""}/>
					<Loading open={states.isLoading}/>
					<Toast close={closeToast} mes={toast}/>
				</div>

			</div>
		</Modal>
    );
}

export default FacesInput;
