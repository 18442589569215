import React from 'react';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
}

const TalkStart = (props:Props)=> {
    return (
		<div className="sysTalk">
			<span className="type">最低人数達成</span>
			<span className="detail">{props.room.Names[Inc.numFaces]}が満足そうに署名のジェスチャーをしました</span>
			<span className="time">{Tools.toTime(props.talk.Date)}</span>
		</div>
	);
}

export default TalkStart;
