import React,{useMemo} from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
//import MultiLines from './MultiLines';
import {Inc,PrivateData} from './Inc';
import {Tools} from './Tools';
//import useStyles from './Styles';

interface Props {
	data:PrivateData,
	close():void,
	isOpen:boolean,
}

const MorningCall = (props:Props)=> {

    let text = useMemo(()=>{
        if(!props.isOpen){
            return "";
        }
        //console.log("morningcall",props);
        if( props.data.IsPlay ){
			if( props.data.History[ props.data.CheckedDay ].VanishedDay>0 ){
				return "ゴーストは眠らない";
			} else if( props.data.CheckedDay>0 && props.data.History.length>props.data.CheckedDay+1 ){
				const now = props.data.History[ props.data.CheckedDay+1 ];
				const prev = props.data.History[ props.data.CheckedDay ];
	            if( now.PlaceId===prev.SelectId || (now.PlaceId==="0" && prev.SelectId==="") ){
    	            return "その夜は"+Inc.placeNames[parseInt(now.PlaceId)] + "で眠った。";
        	    } else {
            	    return Inc.placeNames[parseInt(prev.SelectId)] + "へ向かったが施錠されていたため、ホールで寝ることにした。";
				}
			} else {
				return "その夜はホールで眠った。";
			}
        }
        return "";
    },[props.isOpen])

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={props.close}
        >
            <div id="faces_input" className="modal modalSmall">
                <div className="modalRoot" style={Tools.getModalRootStyle()}>
                    <Paper className="paper modalPaper modalPaperNoHeader">
                        <div>
                            {text}
                        </div>
						{props.data.CheckedDay>0 ? (
						<div style={{marginBottom:"2em"}}>{props.data.CheckedDay+1}日目の朝が来た。</div>
						):(
						<div style={{marginBottom:"2em"}}>新しい朝が来た。</div>
						)}
                        <div style={{position:"absolute",bottom:"1em",left:"0",padding:"0 1em",boxSizing:"border-box",width:"100%"}}>
                        <Button variant="contained" color="default" style={{width:"100%"}} onClick={props.close}>ＯＫ</Button>
                        </div>
                    </Paper>
                </div>
            </div>
        </Modal>
    );
}

export default MorningCall;
