import React,{useMemo} from 'react';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';
import ImgFace from './ImgFace';

interface Props {
	room:Room,
	talk:Talk,
	isArchive:boolean,
}

const TalkAdditional = (props:Props)=> {
	const text = useMemo( ()=>{
		let talks = [];
		talks[0] = (
			<div className="talk talkPublic" key="t0">
				<div className="serial">
					<span className="date">{Tools.toDate(props.talk.Date)}</span>
				</div>
				<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
				<div className="text">
					<h3>{props.room.Names[Inc.numFaces]}</h3>
					<div>
						あと10分待ちましょう<br/>
						延長は最大で5回までです。<br/>
					</div>
				</div>
			</div>
			);
		talks[1] = (
			<div className="sysTalk" key="t1">
				<span className="type">延長</span>
				<span className="detail">締切が延長されました</span>
				<span className="time">{Tools.toTime(props.talk.Date)}</span>
			</div>
			);
		if( props.isArchive ){
			talks.reverse();
		}
	
		return talks;
	},[props.talk,props.isArchive])

    return (
		<React.Fragment>
			{text}
		</React.Fragment>
	);


}

export default TalkAdditional;
