import React,{useMemo} from 'react';
//import MultiLines from './MultiLines';
import ImgFace from './ImgFace';
import {Inc,Room,Talk,PrivateData} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
	data:PrivateData,
	isArchive:boolean,
}

const TalkMorning = (props:Props)=> {
	const text = useMemo(()=>{
		if( props.talk.Day===0 ){
			let auroraId = parseInt(Tools.toDate(props.talk.Date).substr(-2));
			if( props.talk.Message==="1" ){
				auroraId = 0;
			}
			const auroras = ["私、マフをいただきましたの。","えへん、このミルクは中国猫にいただいたものなのです。","クローバーの茎を集めて下さいませんか？","昨晩の流星群にチップを払っていただけましたの？","あなたは共食いをする動物なのかしら？","飛行船が来たかどうかちょっと見てきてくださいな！","狐の集まる庭にしたら素敵じゃない？","叔父様のネクタイピンをこっそり集めるのが趣味ですわ。","クルトンをあと一つだけ下さいな。","屋根裏にまたカンガルーがいらっしゃってるんじゃなくて？"]
			const aurora = auroras[ auroraId % auroras.length ];

			let talks = [];
			talks[0] = (
					<div className="talk talkPublic" key="t0">
						<div className="serial">
							<span className="date">{Tools.toDate(props.talk.Date)}</span>
						</div>
						<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
						<div className="text">
							<h3>{props.room.Names[Inc.numFaces]}</h3>
							<div>
								以上です…<br/>
								では、順番にトージョー手続きをお願いいたします…<br/>
							</div>
						</div>
					</div>
			);
			talks[1] = (
					<div className="talk talkPublic" key="t1">
						<div className="serial">
							<span className="date">{Tools.toDate(props.talk.Date)}</span>
						</div>
						<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+2}/></div>
						<div className="text">
							<h3>{props.room.Names[Inc.numFaces+1]}</h3>
							<div>「{aurora}」</div>
						</div>
					</div>
			);
			talks[2] = (
					<div className="talk talkPublic" key="t2">
						<div className="serial">
							<span className="date">{Tools.toDate(props.talk.Date)}</span>
						</div>
						<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
						<div className="text">
							<h3>{props.room.Names[Inc.numFaces]}</h3>
							<div>
								ハコブネへようこそ、残り物の皆様…<br/>
								まずは、{props.room.Names[Inc.numFaces+1]}から皆様へのご挨拶です。<br/>
							</div>
						</div>
					</div>
			);
			talks[3] = (
					<div className="sysTalk" key="t3">
						<span className="type">はじまり</span>
						<span className="detail">「{props.room.Title}」にハコブネが到着しました</span>
						<span className="time">{Tools.toTime(props.talk.Date)}</span>
					</div>
			);
			if( props.isArchive ){
				talks.reverse();
			}
			return talks;
		}

		if( props.room.Day===props.talk.Day && props.talk.Day>1 && props.room.Status!==Inc.ROOM_STATUS_INSERVICE ){
			let talks = [];
			talks[0] = (
					<div className="talk talkPublic" key="t0">
						<div className="serial">
							<span className="date">{Tools.toDate(props.talk.Date)}</span>
						</div>
						<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
						<div className="text">
							<h3>{props.room.Names[Inc.numFaces]}</h3>
							<div>
								すべての発言と行動が公開されました…<br/>
								残された時間を有意義にご利用ください…<br/>
							</div>
						</div>
					</div>
			)
			talks[1] = (
					<div className="sysTalk" key="t1">
						<span className="type">エピローグ</span>
						<span className="detail">最後の朝になりました</span>
						<span className="time">{Tools.toTime(props.talk.Date)}</span>
					</div>				
			)
			if( props.isArchive ){
				talks.reverse();
			}
			return talks;
		}
	
		let auroraId = props.talk.Message.length+1;
		const auroras = ["今晩はヒンヒンのアモリニアを作りましょうね。","鋭い針でつついた方がよく育つのよ。","窓から入ってくるの。そうしたらもう終わりよ。","あらまあ、力士に噛みつかれたのね、かわいそうに。","峠の向こうのべるとこんべやあで作ってるから安心なの。","そんなところで服を脱ぐものではありませんわ。","まあ、かわいらしい足音ですこと！","砂場で砂鉄を袋いっぱいに集めましょう。","指輪に私の名前を彫ったの。忘れないように。","バイオリンを買ってその中に手紙を隠すのよ。","ねえ、大きな蛙を見たわ。馬車よりずっと大きいのよ。","ふむ、ミントは電気なのかしら。","シーッ！ミーガンは朝になれば帰ってきますからね。","あなた！ポップコーンをお売りになったらどうかしら。","台所に私がいないかどうか見てきてくださる？気づかれちゃダメよ。"]
		const aurora = auroras[ auroraId % auroras.length ];

		let talks = [];
		talks[0] = (
				<div className="talk talkPublic" key="t0">
					<div className="serial">
						<span className="date">{Tools.toDate(props.talk.Date)}</span>
					</div>
					<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+2}/></div>
					<div className="text">
						<h3>{props.room.Names[Inc.numFaces+1]}</h3>
						<div>「{aurora}」</div>
					</div>
				</div>
		)
		talks[1] = (
				<div className="talk talkPublic" key="t1">
					<div className="serial">
						<span className="date">{Tools.toDate(props.talk.Date)}</span>
					</div>
					<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
					<div className="text">
						<h3>{props.room.Names[Inc.numFaces]}</h3>
						<div>
							おはようございます…<br/>
							早速ですが{props.room.Names[Inc.numFaces+1]}が皆様の手相を拝見いたします…<br/>
						</div>
					</div>
				</div>
		)
		talks[2] = (
				<div className="sysTalk" key="t2">
					<span className="type">新しい朝</span>
					<span className="detail">「{props.room.Title}」に{props.talk.Day}日目の朝が来ました。</span>
					<span className="time">{Tools.toTime(props.talk.Date)}</span>
				</div>
		)
		if( props.isArchive ){
			talks.reverse();
		}
		return talks;
	},[props.talk.Day,props.isArchive]);

    return (
		<React.Fragment>
			{text}
		</React.Fragment>
	);
}

export default TalkMorning;
