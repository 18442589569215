import React from 'react';
import {Inc,Room,Talk} from './Inc';
import ImgFace from './ImgFace';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
}

const TalkGreeting = (props:Props)=> {
	return (
		<div className="talk talkPublic">
		<div className="serial">
			<span className="date">{Tools.toDate(props.talk.Date)}</span>
		</div>
		<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
		<div className="text">
			<h3>{props.room.Names[Inc.numFaces]}</h3>
			<div>
				以上です…<br/>
				では皆さん、健やかな一日をお過ごしください…
			</div>
		</div>
	</div>
);
}

export default TalkGreeting;
