import React,{useEffect,useState} from 'react';
//import {Link,Redirect} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//import FormControl from '@material-ui/core/FormControl';
import Modal from '@material-ui/core/Modal';
//import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
//import Tooltip from '@material-ui/core/Tooltip';
//import AutorenewIcon from '@material-ui/icons/Autorenew';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalHeader from './ModalHeader';
//import ColInput from './ColInput';
//import ClimbUp from './ClimbUp';
import Loading from './Loading';
import ImgFace from './ImgFace';
import FaceConfirm from './FaceConfirm';
//import store from './store';
import Toast from './Toast';
import {UserContainer} from './UserContainer';
import {Inc,Face,Room} from './Inc';
import {Tools} from './Tools';
import Yesno from './Yesno';
import Ajax from './Ajax';
//import store from './store';

interface Props {
	isOpen: boolean,
	room:Room,
	close():void,
}

const FacesChoice = (props:Props)=> {
    const [states,setStates] = useState({
    	selectedIndex: 0,
    	selectedIds: [] as number[],
		yesno: "",
        isLoading:true,
	})
	const [toast,setToast] = useState("");
	const userState = UserContainer.useContainer();

	const closeToast = ()=>{
		setToast("");
	}

	const confirm = (event: React.MouseEvent<HTMLElement>)=>{
        if( !event.currentTarget.dataset.index ){
            return;
        }
        const index = parseInt(event.currentTarget.dataset.index);
        if( states.selectedIds.includes(index) ){
        	setToast(props.room.Names[index-1]+"は選べません");
        } else {
			setStates({...states,selectedIndex:index});
		}
	}

	const yes = async ()=>{
		try {
			Ajax.setToken(userState.token);
			const res = userState.user && await Ajax.entry(states.selectedIndex,props.room.Id,userState.user.uid);
			if( res.ok ){
				props.close();
			} else {
				setToast("エントリーできませんでした（"+res.reason+")");
				load();
			}
		} catch(error) {

		}
		
	}

	const no = ()=>{
		setStates({...states,selectedIndex:0});
	}

	const load = async ()=>{
		try {
    		Ajax.setToken(userState.token);
			const res = await Ajax.getMemberCharaIds(props.room.Id);
			if( res.ok ){
				setStates({...states,selectedIds:res.ids,selectedIndex:0,isLoading:false});	
			}
		} catch(error){
			console.log(error);
		}
	}


    useEffect( ()=>{
        if( !userState.ready || !userState.user ){
			return;
		}
		load();
	},[userState.ready,userState.user,props.isOpen]);

    if( !userState.ready || !props.room.Names ){
        return (null);
    }


    return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={props.isOpen}
			onClose={props.close}
		>
			<div id="face_choice" className="modal">
				<ModalHeader close={props.close}/>
				<div className="modalRoot" style={Tools.getModalRootStyle()}>
					<Paper className="paper modalPaper">
						<h2>トージョー手続き</h2>
						<div className="section">
							あなたはどなたですか？
						</div>
						<Grid container spacing={2} className="faces">
						{props.room.Names.map((name:string,index:number)=>{
							if( Inc.numFaces<=index ){
								return (null);
							}
							const key = "face"+index;
							return (
								<Grid item xs={6} md={2} key={key} style={{paddingBottom:"3em"}} onClick={confirm} data-index={index+1}>
									<div className="imgFace">
										<ImgFace code={props.room.CharaSetId} charaId={index+1} shadow={states.selectedIds.includes(index+1)} clickable={!states.selectedIds.includes(index+1)}/>
									</div>
									<div className="FaceName">{name}</div>
								</Grid>
							)
						})}
						</Grid>
						
					</Paper>
					<Yesno yes={yes} no={no} mes={states.yesno} isOpen={states.yesno!==""}/>
					<FaceConfirm yes={yes} no={no} room={props.room} charaId={states.selectedIndex} isOpen={states.selectedIndex>=0}/>
					<Loading open={states.isLoading}/>
					<Toast close={closeToast} mes={toast}/>
				</div>

			</div>
		</Modal>
    );
}

export default FacesChoice;
