import React,{useEffect,useState,useMemo} from 'react';
import {Redirect,useParams} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FaceChoice from './FaceChoice';
import PlayRoomTalks from './PlayRoomTalks';
import MemberList from './MemberList';
import PlayRoomNext from './PlayRoomNext';
import PlayRoomTrap from './PlayRoomTrap';
import MorningCall from './MorningCall';
import MorningNews from './MorningNews';
import Chara from './Chara';
import RoomStatus from './RoomStatus';
import ClimbUp from './ClimbUp';
import Loading from './Loading';
import Toast from './Toast';
import Ajax from './Ajax';
import store from './store';
import TopBar from "./TopBar";
import Help from './Help';
import NoResponse from './NoResponse';
import {UserContainer} from './UserContainer';
import {Inc,Member} from './Inc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
}

const PlayRoom = (props:Props)=> {
    const [states,setStates] = useState({
		room:Inc.defaultRoom(),
		day: 0,
		newDay: 0,
		members: [] as Member[],
		disableds: [] as string[],
		myData: Inc.defaultPrivateData(),
		message: "",
		limit: "",
		pause: false,
		isLoading:false,
		isFaceChoice:false,
		isMorningNews:false,
		isMorningCall:false,
		serverError:false,
		ready:false,
		isMenu:false,
	})
	const [goTop,setGoTop] = useState(false);
	const [toast,setToast] = useState("");
	//const [serverError,setServerError] = useState(false);
	const userState = UserContainer.useContainer();
	const {roomId} = useParams();
	
	const openFaceChoice = ()=>{
		setStates({...states,pause:true,isFaceChoice:true,isMenu:false});
	}
	const closeFaceChoice = ()=>{
		loadRoom(false);
		//setStates({...states,isFaceChoice:false});
	}
	const closeToast = ()=>{
		setToast("");
	}
	const updateDay = ()=>{
		//setStates({...states,isLoading: true});
		loadRoom(false);
	}
    const loadRoom = async (isCall:boolean)=>{
    	if(!roomId){
    		return;
		}
		//console.log("loadRoom");
    	try {
			setStates({...states,isLoading: true});

			Ajax.setToken(userState.token);
			let userId = "";
    		if( userState.user ){
				userId = userState.user.uid;
			}

			const res = await Ajax.getMembers(roomId,userId,true);
			const room = {...res.room,Names:res.room.Names.split("\t")};
			if( room.Listing===false ){
				setGoTop(true);
			} else {
				//console.log("limit",res.limit);
				if( res.ok ){
					if( isCall ){
						setStates({...states,ready:true,room:room,day:res.day,members:res.members,myData:res.myData,limit:res.limit,disableds:res.disableds,pause:true,isFaceChoice:false,isLoading: false,serverError:false,isMorningCall:true});
					} else {
						setStates({...states,ready:true,room:room,day:res.day,members:res.members,myData:res.myData,limit:res.limit,disableds:res.disableds,pause:false,isFaceChoice:false,isLoading: false,serverError:false});
					}
				}
			}
		} catch(error){
			//console.log("load",error);
			//setServerError(true);
			//setStates({...states,serverError:true});
			errorServer();
		}
	}
	const updateNext = (data:Member) => {
		const history = states.myData.History.slice(0,states.myData.History.length-1).concat(data);
		setStates({...states,myData:{...states.myData,History:history}});
	}
	const updateLimit = (date:string) => {
		if( date && states.limit!==date ){
			setStates({...states,limit:date});
		}
	}
	const openMorningCall = async (day:number)=>{
		//console.log("openMorningCall",states);
		if( day>states.room.Day ){
			await loadRoom(true);
		} else {
			setStates({...states,pause:true,isMorningCall:true});
		}
	}
	const closeMorningCall = ()=>{
		if( states.myData.IsPlay ){
			openMorningNews( states.myData.CheckedDay+1 );
		} else {
			openMorningNews( states.room.Day );
		}
	}
	const openMorningNews = (day:number)=>{
		setStates({...states,pause:true,isMorningCall:false,isMorningNews:true,newDay:day});
	}
	const closeMorningNews = async (day:number)=>{
		//const day = states.myData.CheckedDay+1;
		//console.log("closeMorning",day);
		try {
			if( states.myData.IsPlay && states.myData.CheckedDay<day ){
				if(!roomId){
					return;
				}
				let userId = "";
				if( userState.user ){
					userId = userState.user.uid;
				}
				const res = await Ajax.getMembers(roomId,userId,false);
				//await loadRoom(false);
				//setStates({...states,myData:{...states.myData,CheckedDay:day},day:day,newDay:0,pause:false,isMorningNews:false});
				setStates({...states,day:res.day,members:res.members,myData:res.myData,limit:res.limit,disableds:res.disableds,newDay:0,pause:false,isMorningNews:false});
			} else {
				setStates({...states,day:day,newDay:0,pause:false,isMorningNews:false});
			}
		} catch(error){
			errorServer();
		}
	}
	const changeDay = (event: React.MouseEvent<HTMLElement>)=>{
        if( !event.currentTarget.dataset.day ){
            return;
		}
		const day = parseInt(event.currentTarget.dataset.day);
		//console.log("changeDay",day);
		setStates({...states,day:day});
	}
	const errorServer = ()=>{
		//setServerError(true);
		setStates({...states,pause:true,serverError:true});
	}
	const retryServer = async ()=>{
		//setServerError(false);
		await loadRoom(false);
	}
	const buttonDays = useMemo( ()=>{
		let arr = [];
		for( let i=0; i<=states.room.Day; i++ ){
			arr.push(i);
		}
		return (
			<Grid container spacing={1}>
			{arr.map((day:number,index:number)=>{
			const key = "day"+index;
			let label = day + "日";
			if( day===0 ){
				label = "プロローグ";
			} else if( (states.room.Status === Inc.ROOM_STATUS_EPILOGUE || states.room.Status === Inc.ROOM_STATUS_CLOSED) && states.room.Day===day ){
				label = "エピローグ";
			}
			return (
				<Grid item xs={4} sm={2} key={key}>
					<Button key={key} size="small" variant="text" color="default" onClick={changeDay} data-day={day} disabled={day===states.day} style={{width:"100%"}}>{label}</Button>
				</Grid>
			);})}
			</Grid>
		)
	},[states.room.Day,states.day,changeDay]);
	const joinable = useMemo(()=>{
		if( !states.myData.IsPlay && states.room.Day===0 && states.room.Accepting ){
			return true;
		} else {
			return false;
		}
	},[states.myData.IsPlay,states.room.Day,states.room.Accepting,states.members.length]);
	const join = useMemo(()=>{
		return (
			<React.Fragment>
			{userState.user && joinable &&
			<div className="entry">
				<Button variant="contained" color="primary" onClick={openFaceChoice} style={{width:"100%"}}>トージョー手続き</Button>
			</div>
			}
			{!userState.user && joinable &&
			<div className="please">
				ログインすれば参加可
			</div>
			}
			</React.Fragment>
		)
	},[userState.ready,userState.user,joinable,states.room]);
	const side = useMemo(()=>{
		return (
		<div className="roomData">
			<RoomStatus room={states.room} limit={states.limit}/>
			{states.myData.IsPlay &&
			<Chara room={states.room} data={states.myData}/>
			}
			{join}
			{states.myData.IsPlay && states.myData.History[states.myData.CheckedDay].VanishedDay===0 &&
			<PlayRoomNext room={states.room} data={states.myData.History[states.myData.CheckedDay]} disableds={states.disableds} call={openMorningCall} update={updateNext}/>
			}
			{states.myData.IsPlay && states.myData.History[states.myData.CheckedDay].VanishedDay>0 &&
			<PlayRoomTrap room={states.room} data={states.myData.History[states.myData.CheckedDay]} call={openMorningCall} update={updateNext} members={states.members}/>
			}
			<MemberList room={states.room} members={states.members}/>
		</div>
		)
	},[states.room,states.members,states.limit,states.myData,userState.user,join]);
	const openMenu = ()=>{
		setStates({...states,isMenu:true});
	}
	const closeMenu = ()=>{
		setStates({...states,isMenu:false});
	}

    useEffect( ()=>{
		//console.log("effect",userState.user);
		const start = async ()=>{
			await loadRoom(false);
		}
		if( userState.ready ){
			start();
		}
	},[userState.ready,userState.user]);

	if( !userState.ready || !roomId || goTop ){
		return <Redirect to="/"/>
	}

    //if( !states.ready ){
        //return <Loading open={true}/>
	//}
	
	let userId = "";
	if( userState.user && userState.user.uid ){
		userId = userState.user.uid;
	}

    return (
		<React.Fragment>
		<TopBar menu={openMenu}/>
        <div id="main" className="mediumRoot">
			{states.ready &&
			<React.Fragment>
			<Paper className="paper noPad">
				<Grid container spacing={0}>
					<Hidden implementation="js" smDown>
						<Grid item xs={12} md={3}>
							<div style={{padding:"1em"}}>
							{side}
							</div>
						</Grid>
					</Hidden>
					<Grid item xs={12} md={9}>
						<div className="roomTimeline">
							<div className="roomDays">
								{buttonDays}
							</div>
							<Hidden implementation="js" mdUp>
							{join}
							</Hidden>
							<PlayRoomTalks room={states.room} userId={userId} data={states.myData} day={states.day} refresh={updateDay} pause={states.pause} call={openMorningCall} limit={updateLimit} news={openMorningNews} error={errorServer}/>
						</div>
					</Grid>
				</Grid>
			</Paper>
            <ClimbUp/>
			<MorningCall isOpen={states.isMorningCall} data={states.myData} close={closeMorningCall}/>
			<MorningNews isOpen={states.isMorningNews} room={states.room} userId={userId} day={states.newDay} data={states.myData} close={closeMorningNews}/>
			</React.Fragment>
			}
            <FaceChoice isOpen={states.isFaceChoice} close={closeFaceChoice} room={states.room}/>
			<Loading open={states.isLoading || !states.ready}/>
			<Toast close={closeToast} mes={toast}/>
			<NoResponse isOpen={states.serverError} close={retryServer}/>
			<Drawer anchor="left" open={states.isMenu} onClose={closeMenu}>
				<Box className="drawer">
					<Box style={{textAlign:"right"}}>
						<IconButton size="small" onClick={closeMenu}><FontAwesomeIcon icon="arrow-left"/></IconButton>
					</Box>
					{side}
				</Box>
			</Drawer>
			<Help/>
        </div>
		</React.Fragment>
    );
}

export default PlayRoom;
