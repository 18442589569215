import React,{useState, useEffect} from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'//yarn add @types/react-router-dom
import {MuiThemeProvider} from '@material-ui/core/styles';
//import TopBar from "./TopBar";
import Main from "./Main";
import MyCharaSets from './MyCharaSets';
import PlayRoom from './PlayRoom';
import ViewRoom from './ViewRoom';
import Login from "./Login";
import MailNew from './MailNew';
import MailUpdate from './MailUpdate';
import OldVersion from './OldVersion';
import NoService from './NoService';
import NoResponse from './NoResponse';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCopyright,faUserCircle,faEnvelope,faExclamationTriangle,faGhost,faVolumeMute,faDove,faBed,faGlasses,faArrowDown,faArrowLeft,faKiwiBird,faCut,faSatelliteDish,faCaretDown,faHandScissors,faHandPaper,faFistRaised,faSkullCrossbones,faSortNumericUpAlt,faSortNumericDown} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {Theme} from './Theme';
import Ajax from './Ajax';
import "./App.scss";
import {UserContainer} from './UserContainer';

const App: React.FC = () => {
  const basename = process.env.REACT_APP_BASENAME;
  const [redirectTo,setRedirectTo] = useState("");
  const [version,setVersion] = useState("");
  const [available,setAvailable] = useState(true);
  const [serverError,setServerError] = useState(false);
  library.add(faCopyright,faUserCircle,faEnvelope,faExclamationTriangle,faGhost,faVolumeMute,faDove,faBed,faGlasses,faArrowDown,faArrowLeft,faKiwiBird,faCut,faSatelliteDish,faCaretDown,faHandScissors,faHandPaper,faFistRaised,faSkullCrossbones,faSortNumericUpAlt,faSortNumericDown,fab);
  const hello = async ()=>{
	await Ajax.hello().then((res)=>{
		if( res.ok ){
			if( res.version!==process.env.REACT_APP_VERSION ){
				setVersion(res.version);
			} else if( res.available===false ){
				setAvailable(false);
			}
			//console.log("hello","true");
		} else {
			//console.log("hello","false");
			setServerError(true);
		}
	}).catch((error)=>{
		console.log(error);
		setServerError(true);
	});
  }

  useEffect(()=>{
	hello();
  },[]);

  return (
    <BrowserRouter basename={basename}>
      <MuiThemeProvider theme={Theme}>
        {redirectTo!=="" &&
        <Redirect to={redirectTo}/>
        }
        <UserContainer.Provider>
			<div className="App">
				<Switch>
				<Route exact path='/' render={()=><Main/>}/>
				<Route exact path='/mychara' render={()=><MyCharaSets/>}/>
				<Route exact path='/login' render={()=><Login/>}/>
				<Route exact path='/mail/new' render={()=><MailNew/>}/>
				<Route exact path='/mail/update' render={()=><MailUpdate/>}/>              
				<Route exact path='/play/:roomId' render={()=><PlayRoom/>}/>
				<Route exact path='/view/:roomId' render={()=><ViewRoom/>}/>
				</Switch>
			</div>
        </UserContainer.Provider>
		<OldVersion latest={version} isOpen={version!==""}/>
		<NoService isOpen={!available}/>
		<NoResponse isOpen={serverError}/>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
