import {Room} from './Inc';
//import {Tools} from './Tools';

export default class Ajax {
	static token:string = "";
	static url = process.env.REACT_APP_SYSTEM_URL;
	static setToken = (tk:string)=>{
		Ajax.token = tk;
	}
	static send = (path:string,data:any,func:any)=>{
		//console.log("send",Ajax.url+path,data);
		fetch(Ajax.url+path, {
			method : 'POST',
			body : JSON.stringify(data),
			credentials: "include",
		}).then(response => response.json())
		.then((json) => {
			func(json);
		})
		.catch(err => {
			console.log('rejected:', err); // rejected: TypeError: Failed to fetch
		});
	}

	static hello = async ()=>{
		try {
			return await fetch(Ajax.url+"hello",{
				method: 'POST',
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static newToken = async (userId:string)=>{
		try {
			const data = {
				UserId: userId,
			};
			return await fetch(Ajax.url+"token/get",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static getAllRooms = async ()=>{
		try {
			return await fetch(Ajax.url+"room/all",{
				method: 'POST',
				body: "",
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static entry = async (charaId:number,roomId:string,userId:string)=>{
		try {
			const data = {
				UserId: userId,
				RoomId: roomId,
				CharaId: charaId,
				Token:Ajax.token,
			};
			return await fetch(Ajax.url+"entry",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static checkMorning = async (roomId:string,userId:string)=>{
		try {
			let data = {RoomId:roomId,Token:Ajax.token,UserId:userId};
			return await fetch(Ajax.url+"morning/check",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static getMorning = async (roomId:string,userId:string,day:number)=>{
		//day=0　→　最新の朝を取得
		//day>0　→　指定した日の朝を取得
		try {
			let data = {RoomId:roomId,Token:Ajax.token,UserId:userId,Day:day};
			return await fetch(Ajax.url+"morning/get",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static getMembers = async (roomId:string,userId:string,flag:boolean)=>{
		try {
			let data = {RoomId:roomId,Token:Ajax.token,UserId:userId,Flag:flag};
			return await fetch(Ajax.url+"member/list",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static getAllMembers = async (roomId:string,day:number)=>{
		try {
			let data = {RoomId:roomId,Token:Ajax.token,Day:day};
			return await fetch(Ajax.url+"member/all",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}


	static getMemberCharaIds = async (roomId:string)=>{
		try {
			return await fetch(Ajax.url+"member/charaids",{
				method: 'POST',
				body: JSON.stringify({RoomId:roomId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getMyCharaSets = async (userId:string)=>{
		try {
			return await fetch(Ajax.url+"charaset/list",{
				method: 'POST',
				body: JSON.stringify({UserId:userId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static getPublicCharaSets = async (userId:string)=>{
		try {
			return await fetch(Ajax.url+"charaset/public",{
				method: 'POST',
				body: JSON.stringify({UserId:userId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static isSameCharaset = async (title:string,code:string)=>{
		try {
			return await fetch(Ajax.url+"charaset/check",{
				method: 'POST',
				body: JSON.stringify({Title:title,Code:code,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}


	static addTalk = async (roomId:string,day:number,userId:string,text:string,flag:boolean)=>{
		try {
			return await fetch(Ajax.url+"talk/add",{
				method: 'POST',
				body: JSON.stringify({RoomId:roomId,Day:day,UserId:userId,Text:text,Flag:flag,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getLatestTalks = async (roomId:string,lastId:number,userId?:string)=>{
		try {
			let data = {RoomId:roomId,LastId:lastId,Token:Ajax.token,UserId:""};
			if( userId ){
				data.UserId = userId;
			}
			return await fetch(Ajax.url+"talk/list",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static getPastTalks = async (roomId:string,firstId:number,userId?:string)=>{
		try {
			let data = {RoomId:roomId,FirstId:firstId,Token:Ajax.token,UserId:""};
			if( userId ){
				data.UserId = userId;
			}
			return await fetch(Ajax.url+"talk/list",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getTalks = async (roomId:string,roomDay:number,day:number,lastId:number,firstId:number,userId:string,archive:boolean,all:boolean)=>{
		try {
			let data = {RoomId:roomId,RoomDay:roomDay,Day:day,FirstId:firstId,LastId:lastId,Flag:archive,Token:Ajax.token,UserId:userId};
			//if( userId!=="" ){
			//	data.UserId = userId;
			//}
			//console.log("getTalks",data);
			let path = "talk/list";
			if( all ){
				path = "talk/all";
			}
			return await fetch(Ajax.url+path,{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static setNext = async (roomId:string,day:number,userId:string,selectId:string)=>{
		try {
			return await fetch(Ajax.url+"next/set",{
				method: 'POST',
				body: JSON.stringify({RoomId:roomId,Day:day,UserId:userId,SelectId:selectId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}


	static setFace = async (data:string,userId:string,charaId:number)=>{
		try {
			return await fetch(Ajax.url+"face/set",{
				method: 'POST',
				body: JSON.stringify({Data:data,UserId:userId,CharaId:charaId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static clearFace = async (userId:string)=>{
		try {
			return await fetch(Ajax.url+"face/clear",{
				method: 'POST',
				body: JSON.stringify({UserId:userId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static updateFace = async (id:string,title:string,permission:string,names:string[],userId:string)=>{
		try {
			return await fetch(Ajax.url+"face/update",{
				method: 'POST',
				body: JSON.stringify({UserId:userId,Code:id,Title:title,Permission:permission,Names:names,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static deleteFace = async (id:string,userId:string)=>{
		try {
			return await fetch(Ajax.url+"face/delete",{
				method: 'POST',
				body: JSON.stringify({UserId:userId,Code:id,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static addRoom = async (room:Room)=>{
		try {
			return await fetch(Ajax.url+"room/add",{
				method: 'POST',
				body: JSON.stringify({Room:room,Token:Ajax.token},function(key,value){
					if( key==="CreatedAt" || key==="UpdatedAt" ){
						return null;
					}
					return value;
				}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static updateRoom = async (room:Room,roomId:string)=>{
		try {
			return await fetch(Ajax.url+"room/update",{
				method: 'POST',
				body: JSON.stringify({RoomId:roomId,Room:room,Token:Ajax.token},function(key,value){
					if( key==="CreatedAt" || key==="UpdatedAt" ){
						if( value.toDate() ){
							return value.toDate().toISOString();
						} else {
							return null;
						}
					}
					return value;
				}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static removeRoom = async (roomId:string)=>{
		try {
			return await fetch(Ajax.url+"room/remove",{
				method: 'POST',
				body: JSON.stringify({RoomId:roomId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static resetCacheRoom = async (roomId:string,userId:string)=>{
		try {
			return await fetch(Ajax.url+"room/reset",{
				method: 'POST',
				body: JSON.stringify({RoomId:roomId,UserId:userId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static getRoom = async (roomId:string)=>{
		try {
			return await fetch(Ajax.url+"room/get",{
				method: 'POST',
				body: JSON.stringify({RoomId:roomId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}


}