import React,{useMemo} from 'react';
import {Inc,Room,Talk} from './Inc';
import ImgFace from './ImgFace';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
	isArchive:boolean,
}

const TalkJournal = (props:Props)=> {
	const text = useMemo( ()=>{
		let talks = [];
		talks[0] = (
			<div className="talk talkPublic">
				<div className="serial">
					<span className="date">{Tools.toDate(props.talk.Date)}</span>
				</div>
				<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+2}/></div>
				<div className="text">
					<h3>{props.room.Names[Inc.numFaces+1]}</h3>
					<div>
						「そうなんですね！」
					</div>
				</div>
			</div>
			);
		talks[1] = (
			<div className="talk talkPublic">
				<div className="serial">
					<span className="date">{Tools.toDate(props.talk.Date)}</span>
				</div>
				<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
				<div className="text">
					<h3>{props.room.Names[Inc.numFaces]}</h3>
					<div>
						{props.talk.Message!=="" ? (
							<span>{props.room.Names[Inc.numFaces+1]}の占いによると、亡霊たちは <b>{props.room.GhostItems.split(",")[parseInt(props.talk.Message)-1]}</b> のことを考えているようです…</span>
						):(
							<span>{props.room.Names[Inc.numFaces+1]}の占いによると、亡霊たちは息を潜めているようです…</span>
						)}
					</div>
				</div>
			</div>
			);

		talks[2] = (
			<div className="talk talkPublic">
				<div className="serial">
					<span className="date">{Tools.toDate(props.talk.Date)}</span>
				</div>
				<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+2}/></div>
				<div className="text">
					<h3>{props.room.Names[Inc.numFaces+1]}</h3>
					<div>
						「なるほどですね！」
					</div>
				</div>
			</div>
			);
		if( props.isArchive ){
			talks.reverse();
		}
		return talks;
	},[props.talk,props.isArchive]);

    return (
		<React.Fragment>
			{text}
		</React.Fragment>
	);
}

export default TalkJournal;
