import React,{useState} from 'react';
import {Link,useLocation} from 'react-router-dom'//yarn add @types/react-router-dom
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CloseIcon from '@material-ui/icons/Close';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import MailIcon from '@material-ui/icons/Mail';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';


//import Drawer from '@material-ui/core/Drawer';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Logo from './Logo';
import {Theme} from './Theme';
import {UserContainer} from './UserContainer';
import "./TopBar.scss";

interface Props {
	//redirect(to:string):void,
	menu?():void,
}

const TopBar = (props:Props)=> {
    const userState = UserContainer.useContainer();
    const [states,setStates] = useState({
		isPersonMenuOpen: false,
		isMenu: false,
        redirectTo: "",
    });
	const openPersonMenu = ()=>{
		if( !states.isPersonMenuOpen ){
			setStates({...states,isPersonMenuOpen:true});
		}
	}
	const closePersonMenu = ()=>{
		if( states.isPersonMenuOpen ){
			setStates({...states,isPersonMenuOpen:false});
		}
	}
	const openMenu = ()=>{
		//setStates({...states,isMenu:true});
		if( props.menu ){
			props.menu();
		}
	}
	const closeMenu = ()=>{
		setStates({...states,isMenu:false});
	}
    const logout = ()=>{
        userState.signOut();
        setStates({...states,isPersonMenuOpen:false});
        //props.redirect("/login");
    }
	const openHelp = ()=>{
		
	}
    const location = useLocation();
    //useEffect( ()=>{
    //    console.log(location);
    //},[location]);

	let provider = "メール";
	if( userState.user ){
		if( userState.user.providerData[0] ){
			if( userState.user.providerData[0].providerId==="twitter.com" ){
				provider = "twitter";
			}
			if( userState.user.providerData[0].providerId==="google.com" ){
				provider = "google";
			}
		}
	}

    return (
		<React.Fragment>
        <AppBar position="fixed" className="appbar" style={{zIndex:Theme.zIndex.drawer+1}}>
            <Toolbar variant="dense" className="toolbar">
				<div className="logo">
					<Logo/>
				</div>
				{props.menu &&
				<Hidden implementation="js" mdUp>
               		<Tooltip title="行動メニュー"><IconButton onClick={openMenu} className="menuButton"><MenuIcon style={{fontSize:"140%"}}/></IconButton></Tooltip>
				</Hidden>
				}
				{userState.ready &&
				<React.Fragment>
                {userState.user ? (
                    <IconButton color="default" className="personButton" aria-label="person" onClick={openPersonMenu}>
                        <Tooltip title="ユーザー情報"><AccountBoxIcon style={{fontSize:"140%"}}/></Tooltip>
                    </IconButton>
				):(
					<Link to="/login" className="personButton"><Tooltip title="ログイン"><Button variant="text" color="default" size="small" className="loginButton">ログイン</Button></Tooltip></Link>
				)}
				</React.Fragment>
				}
            </Toolbar>
            {states.isPersonMenuOpen &&
				<div style={{position:"fixed",top:"2.5em",right:"10px",maxWidth:"300px",minWidth:"200px",zIndex:9000}}>
					<ClickAwayListener onClickAway={closePersonMenu}>
						<Paper id="menu-list-grow" style={{paddingTop:"0.20em"}}>
							<div style={{padding:"1em"}}>
								<div className="personMenu">
									<React.Fragment>
									<h3 className="withIcon"><VerifiedUserIcon/>アカウント</h3>
									<ul className="personData">
									{userState.user?.displayName &&
										<li>
											{userState.user?.displayName}
										</li>
									}
										<li style={{fontSize:"90%",color:"#999999"}}>{provider}でログイン</li>
									</ul>
									</React.Fragment>

									{userState.user?.email &&
									<React.Fragment>
									<h3 className="withIcon"><MailIcon/>メールアドレス</h3>
									<ul className="personData">
										<li>
											{userState.user?.email}
										</li>
									</ul>
									</React.Fragment>
									}
								</div>
								<FormControl fullWidth={true}>
									<Button variant="contained" color="primary" onClick={logout}>ログアウト</Button>
								</FormControl>
							</div>
							<div style={{backgroundColor:"#EEEEEE",textAlign:"center"}}>
								<Button onClick={closePersonMenu} color="default" style={{width:"100%"}}><CloseIcon/>閉じる</Button>
							</div>
						</Paper>
					</ClickAwayListener>
				</div>
			}
			<Drawer anchor="left" open={states.isMenu} onClose={closeMenu}>
				<Box className="drawer">
					<Typography variant="h6" component="h3" className="menuTitle">
						メニュー
					</Typography>
					<List style={{marginBottom:"4em"}}>
						<ListItem button onClick={openHelp} data-id="guide">
							<ListItemText primary="ヘルプ"/>
						</ListItem>
					</List>
					<Typography variant="h6" component="h3" className="menuTitle">
						姉妹サイト
					</Typography>
					<Box className="menuLinks">
						<a href="https://store.retro-biz.com/" rel="noopener noreferrer" target="_blank" style={{textDecoration:"none",color:"#333333"}}>
							<img src="https://store.retro-biz.com/img/common/logo.png" style={{width:"90%"}}/>
							<div className="menuCaption">
								戯曲の電子書籍販売あります<br/>
							</div>
						</a>
					</Box>
				</Box>
			</Drawer>
        </AppBar>
				
				</React.Fragment>
    )
}

export default TopBar;