import React,{useMemo} from 'react';
import ImgFace from './ImgFace';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
	isArchive:boolean,
}

const TalkEntry = (props:Props)=> {
	const text = useMemo( ()=>{
		let talks = [];
		talks[0] = (
				<div className="talk talkPublic" key="t0">
					<div className="serial">
						<span className="date">{Tools.toDate(props.talk.Date)}</span>
					</div>
					<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+2}/></div>
					<div className="text">
						<h3>{props.room.Names[Inc.numFaces+1]}</h3>
						<div>
							{props.room.Names[props.talk.CharaId-1]}{props.talk.Message}
						</div>
					</div>
				</div>
			);
		talks[1] = (
				<div className="sysTalk" key="t1">
					<span className="type">エントリ</span>
					<span className="detail">{props.room.Names[props.talk.CharaId-1]}が{props.room.Names[Inc.numFaces+1]}に名を告げました</span>
					<span className="time">{Tools.toTime(props.talk.Date)}</span>
				</div>
			);
		if( props.isArchive ){
			talks.reverse();
		}
		return talks;
	},[props.talk,props.isArchive]);

    return (
		<React.Fragment>
			{text}
		</React.Fragment>
	);
}

export default TalkEntry;
