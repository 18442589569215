import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ImgFace from './ImgFace';
//import MultiLines from './MultiLines';
import {Tools} from './Tools';
import {Room} from './Inc';
//import useStyles from './Styles';

interface Props {
    room: Room,
    charaId: number,
    yes():void,
    no():void,
    isOpen:boolean,
}

const FaceConfirm = (props:Props)=> {
    //const classes = useStyles();
    if(props.charaId===0){
        return (null);
    }

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={props.no}
        >
            <div id="faces_input" className="modal modalSmall">
                <div className="modalRoot" style={Tools.getModalRootStyle()}>
                    <Paper className="paper modalPaper modalPaperNoHeader">
                        <div>
                            <ImgFace code={props.room.CharaSetId} charaId={props.charaId}/>
                        </div>
                        <div style={{marginBottom:"2em"}}>
                            「{props.room.Names[props.charaId-1]}」でよろしいですか？
                        </div>
                        <div style={{position:"absolute",bottom:"1em",left:"0",padding:"0 1em",boxSizing:"border-box",width:"100%"}}>
                        <Button variant="contained" color="default" style={{width:"40%"}} onClick={props.no}>いいえ</Button>
                        <Button variant="contained" color="primary" style={{width:"60%"}} onClick={props.yes}>はい</Button>
                        </div>
                    </Paper>
                </div>
            </div>
        </Modal>
    );
}

export default FaceConfirm;
