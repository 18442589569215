import React,{useEffect,useState,useRef,useCallback} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
//import ImgFace from './ImgFace';
import ColInput from './ColInput';
//import MultiLines from './MultiLines';
import TinyLoading from './TinyLoading';
import TalkMorning from './TalkMorning';
import TalkGreeting from './TalkGreeting';
import TalkJournal from './TalkJournal';
import TalkPublic from './TalkPublic';
import TalkPrivate from './TalkPrivate';
import TalkGhost from './TalkGhost';
import TalkNext from './TalkNext';
import TalkTrap from './TalkTrap';
import TalkItem from './TalkItem';
import TalkEntry from './TalkEntry';
import TalkStart from './TalkStart';
import TalkNight from './TalkNight';
import TalkTimeout from './TalkTimeout';
import TalkResult from './TalkResult';
import TalkDisabled from './TalkDisabled';
import TalkClosed from './TalkClosed';
import TalkAdditional from './TalkAdditional';
import SortUp from './SortUp';
import Toast from './Toast';
//import {UserContainer} from './UserContainer';
import {Inc,Room,Talk,PrivateData} from './Inc';
import Ajax from './Ajax';

interface Props {
	room:Room,
	data:PrivateData,
	day:number,
	pause:boolean,
	userId:string,
	limit(date:string):void,
	call(day:number):void,
	news(day:number):void,
	refresh():void,
	error():void,
}

const PlayRoomTalks = (props:Props)=> {
	const [states,setStates] = useState({
		isLoading:true,
		isLoadingPast:false,
		isArchive:false,
		newMorningId:"",
	});
	const [tid,setTid] = useState(0);
	const refTid = useRef(tid);
	const [talks,setTalks] = useState([] as Talk[]);
	const refTalks = useRef(talks);
	const [closed,setClosed] = useState(false);
	const refClosed = useRef(closed);
	const [manual,setManual] = useState(false);
	const refManual = useRef(manual);
	const [toast,setToast] = useState("");
	const [message,setMessage] = useState("");
	//const userState = UserContainer.useContainer();
	
	const closeToast = ()=>{
		setToast("");
	}
	const changeMessage = (name:string,value:string)=>{
		//setStates({...states,message:value});
		setMessage(value);
	}
	const validate = async (event: React.MouseEvent<HTMLElement>)=>{
        if( !event.currentTarget.dataset.flag ){
            return;
		}
		if( message.trim()==="" ){
			return;
		}
		if( message.length > Inc.numMessageMax ){
			setToast("メッセージが長すぎます");
			return;
		}
		let flag = true;
		if(event.currentTarget.dataset.flag==="0"){
			flag = false;
		}
		try {
			clearRefresh();
			setStates({...states,isLoading:true});
			const res = await Ajax.addTalk(props.room.Id,props.room.Day,props.userId,message.trim(),flag);
			//console.log("addtalk",res);
			if( res.ok ){
				setMessage("");
				loadLatestTalks();
			} else {
				clearRefresh();
				if( res.reason==="anotherday" ){
					props.call( props.data.CheckedDay+1 );
					//setStates({...states,isLoading:false,newMorningId:res.placeId});
					//setToast("AnotherDay!");
					//props.refresh();
				} else if( res.reason==="outofservice" ){
					setStates({...states,isLoading:false});
					setToast("書き込めません");
					props.refresh();
				} else {
					setStates({...states,isLoading:false});
					setToast("書き込めません");
				}
			}
		} catch(error) {
			//props.error();
			setStates({...states,isLoading:false});
			setToast("書き込めません");
		}

	}
	const loadLatestTalks = async ()=>{
		setStates({...states,isLoading:true});
		try {
			const ok = await loadTalks(false);
			setStates({...states,isLoading:false});
			//console.log("load","talkable:",talkable());
			if( ok ){
				if( props.room.Day===props.day && !refClosed.current &&
					(props.room.Status===Inc.ROOM_STATUS_EPILOGUE || props.room.Status===Inc.ROOM_STATUS_INSERVICE || props.room.Status===Inc.ROOM_STATUS_WAITING )
				){
					//clearRefresh();
					refTid.current = window.setTimeout(()=>{
						loadLatestTalks();
					},5000);
					//console.log("loadLatestTalks","setTimeout",refTid.current);
				} else {
					//console.log("loadLatestTalks","stop");
				}
			} else {
				//setStates({...states,isLoading:false});
				//console.log("loadLatestTalks","error1");
				props.error();
			}
		} catch(error) {
			setStates({...states,isLoading:false});
			//console.log("loadLatestTalks","error2");
			props.error();
		}
	}
	const loadPastTalks = async ()=>{
		refManual.current = false;
		setStates({...states,isLoadingPast:true});
		await loadTalks(true);
		setStates({...states,isLoadingPast:false});
	}
	const loadPastTalksManual = async ()=>{
		refManual.current = true;
		setStates({...states,isLoadingPast:true});
		await loadTalks(true);
		setStates({...states,isLoadingPast:false});
	}

    const loadTalks = async (past:boolean)=>{
    	if(!props.room){
    		return;
		}
		
    	try {
			let lastId = 0
			let firstId = 0;
			if( refTalks.current.length>0 ){
				if(past){
					firstId = refTalks.current[ refTalks.current.length-1 ].Id;					
				} else {
					lastId = refTalks.current[0].Id;;
				}
			}
			let roomDay = props.room.Day;
			if( props.userId!=="" && props.data.CharaId>0 && roomDay>props.data.CheckedDay ){
				roomDay = props.data.CheckedDay;
			}
			let all = false;
			if( props.room.Status!==Inc.ROOM_STATUS_INSERVICE && props.room.Status!==Inc.ROOM_STATUS_WAITING ){
				all = true;
			}
			const res = await Ajax.getTalks(props.room.Id,roomDay,props.day,lastId,firstId,props.userId,states.isArchive,all);
			//console.log(res);
			if( res.ok ){
	    		let talks;
	    		if( res.talks[0] ){
	    			if(past){
	    				talks = refTalks.current.concat(res.talks);
	    			} else {
	    				talks = res.talks.concat(refTalks.current);
	    			}
	    		} else {
					talks = refTalks.current;
					if( states.isArchive && refManual.current ){
						//console.log("nomore");
						setToast("これ以上の新しいログはありません");
					}
				}
				refTalks.current = talks;
				refClosed.current = res.closed;
				if( res.isMorning ){
					//morning call
					props.call( res.day );
				}
				if( props.data.History && props.data.History[props.data.CheckedDay+1] && !props.data.History[props.data.CheckedDay+1].Checked ){
					props.call( props.data.CheckedDay+1 );
				}
				if( res.nextTime && res.nextTime !== props.room.NextTime ){
					props.limit(res.nextTime);
				} else {
					//console.log("nextTime","same",res.nextTime,props.room.NextTime);
				}
				return true;
			} else {
				console.log("load","error");
				return false;
			}
			
		} catch(error){
			console.log("load",error);
			return false;
		}
	}
	const clearRefresh = ()=>{
		//console.log("clearRefresh");
		if( refTid.current>0 ){
			//console.log("tid",refTid.current);
			window.clearTimeout(refTid.current);
			refTid.current = 0;
		}
	}
	const showMorning = ()=>{
		props.news(props.day);
	}
	const talkable = useCallback( ()=>{
		if( states.isArchive ){
			return false;
		}
		if( refClosed.current ){
			return false;
		}
		if( props.data.CharaId>0 && props.room.Day===props.day && (props.room.Status===Inc.ROOM_STATUS_INSERVICE || props.room.Status===Inc.ROOM_STATUS_WAITING || props.room.Status===Inc.ROOM_STATUS_EPILOGUE)){
			return true;
		} else {
			return false;
		}
	},[props.data.CharaId,props.room.Day,props.day,props.room.Status,states.isArchive,refClosed.current]);

	const updateArchive = (v:boolean)=>{
		setStates({...states,isArchive:v});
	}

	useEffect( ()=>{
		refTalks.current = [] as Talk[];
		clearRefresh();
		return ()=>{
			clearRefresh();
		}
	},[props.day,props.userId,states.isArchive]);

	useEffect( ()=>{
		//console.log("play_room_talks_data",props.data);
		//if( !userState.ready ){
		//	return;
		//}
		clearRefresh();
		if( props.room.Id ){
			if( props.pause ){
				//clearRefresh();
			} else {
				if( refTid.current===0 ){
					if( states.isArchive ){
						loadPastTalks();
					} else {
						loadLatestTalks();
					}
				}
			}
		}
		if( props.room.Status === Inc.ROOM_STATUS_CLOSED || props.room.Status === Inc.ROOM_STATUS_CANCELED ){
			refClosed.current = true;
		}
	},[props.room.Id,props.day,props.pause,props.userId,states.isArchive])

    //if( !userState.ready ){
    //    return (null);
    //} else {
    //	Ajax.setToken(userState.token);
	//}
	
	let isFirst = false;
	if( refTalks.current.length>0 ){
		if( refTalks.current[refTalks.current.length-1].TypeId==="M" || refTalks.current[refTalks.current.length-1].TypeId==="R" ){
			isFirst = true;
		}
		//console.log(isFirst,refTalks.current[refTalks.current.length-1].TypeId);
	} else {
		isFirst = true;
	}

	let num = Inc.numMessageMax - message.length;
	let classCounter = "counter";
	if( num===0 ){
		classCounter = "counter zero";
	} else if( num<0 ){
		classCounter = "counter over";
	}

    return (
		<div id="play_room_talks">
			{talkable() &&
			<FormControl fullWidth={true} className="formMessage">
				<div className="message">
					<ColInput id="message" value={message} type="textarea" rows={6} change={changeMessage} name="message" dense={true} noName={true}/>
					<div className={classCounter}>{num}</div>
				</div>
				{props.room.Status===Inc.ROOM_STATUS_EPILOGUE &&
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl fullWidth={true}>
							<Button variant="contained" color="primary" onClick={validate} data-flag="1">エピローグ</Button>					
						</FormControl>
					</Grid>
				</Grid>
				}
				{props.room.Status!==Inc.ROOM_STATUS_EPILOGUE && props.data.History[props.data.History.length-1].VanishedDay===0 &&
				<Grid container spacing={1}>
					<Grid item xs={5}>
						<FormControl fullWidth={true}>
							<Button variant="outlined" color="primary" onClick={validate} data-flag="0" className="btnWhite">ひとりごと</Button>
						</FormControl>
					</Grid>
					<Grid item xs={7}>
						<FormControl fullWidth={true}>
							<Button variant="contained" color="primary" onClick={validate} data-flag="1">みんなに言う</Button>					
						</FormControl>
					</Grid>
				</Grid>
				}
				{props.room.Status!==Inc.ROOM_STATUS_EPILOGUE && props.data.History[props.data.History.length-1].VanishedDay>0 &&
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl fullWidth={true}>
							<Button variant="outlined" color="primary" onClick={validate} data-flag="0" className="btnWhite">つぶやき</Button>
						</FormControl>
					</Grid>
				</Grid>
				}
			</FormControl>
			}
			<div className="loadingTop">
				<TinyLoading isLoading={states.isLoading}/>
				{props.day>0 &&
				<Button variant="outlined" color="default" size="small" onClick={showMorning} className="btnNews">朝のニュース</Button>
				}
			</div>
			<div className="talks">
			{refTalks.current.map((talk:Talk,index:number)=>{
				const key = "talk"+index;
				if( talk.TypeId==="A" ){
					return <TalkAdditional room={props.room} talk={talk} isArchive={states.isArchive} key={key}/>
				}
				if( talk.TypeId==="M" ){
					return <TalkMorning room={props.room} talk={talk} data={props.data} isArchive={states.isArchive} key={key}/>
				} 
				if( talk.TypeId==="G" ){
					return <TalkGreeting room={props.room} talk={talk} key={key}/>
				} 
				if( talk.TypeId==="J" ){
					return <TalkJournal room={props.room} talk={talk} isArchive={states.isArchive} key={key}/>
				} 
				if( talk.TypeId==="X" ){
					return <TalkNext room={props.room} talk={talk} key={key}/>
				}
				if( talk.TypeId==="Z" ){
					return <TalkTrap room={props.room} talk={talk} key={key}/>
				}
				if( talk.TypeId==="I" ){
					return <TalkItem room={props.room} talk={talk} key={key}/>
				}
				if( talk.TypeId==="E" ){
					return <TalkEntry room={props.room} talk={talk} isArchive={states.isArchive} key={key}/>
				}
				if( talk.TypeId==="S" ){
					return <TalkStart room={props.room} talk={talk} key={key}/>
				}
				if( talk.TypeId==="N" ){
					return <TalkNight room={props.room} talk={talk} isArchive={states.isArchive} key={key}/>
				}
				if( talk.TypeId==="T" ){
					return <TalkTimeout room={props.room} talk={talk} isArchive={states.isArchive} key={key}/>
				}
				if( talk.TypeId==="R" ){
					return <TalkResult room={props.room} talk={talk} key={key}/>
				}
				if( talk.TypeId==="D" ){
					return <TalkDisabled room={props.room} talk={talk} key={key}/>
				}
				if( talk.TypeId==="C" ){
					return <TalkClosed room={props.room} talk={talk} isArchive={states.isArchive} key={key}/>
				}
				if( talk.TypeId==="3" ){
					return <TalkGhost room={props.room} talk={talk} key={key}/>
				}
				if( talk.TypeId==="2" ){
					return <TalkPrivate room={props.room} talk={talk} key={key}/>
				}
				if( talk.TypeId==="1" ){
					return <TalkPublic room={props.room} talk={talk} key={key}/>
				}
				return (
					<div key={key} className="sysTalk">{talk.TypeId}-{talk.CharaId}</div>
				)
			})}
				<div className="loadingBottom">
					<TinyLoading isLoading={states.isLoadingPast}/>
				</div>
			</div>
			{!isFirst && !states.isLoadingPast &&
				<div className="more">
					<Button variant="outlined" color="default" onClick={loadPastTalksManual} className="btnWhite">もっと見る</Button>
				</div>
			}
			<SortUp isArchive={states.isArchive} update={updateArchive}/>
			<Toast close={closeToast} mes={toast}/>
        </div>
    );
}

export default PlayRoomTalks;
