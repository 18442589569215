import { createMuiTheme } from '@material-ui/core/styles';

export const Theme = createMuiTheme({
    palette: {
        primary: {
			light: '#86c6b6',
			main: '#75b5a5',
            dark: '#64a494',
            contrastText: '#FFF2CA',
        },
        secondary: {
            light: '#d17d7c',
			main: '#c06c6b',
			dark: '#a95b5a',
			contrastText: '#FFFFFF',
		},
		error: {
			light: '#FF0000',
			main: '#DD0000',
			dark: '#BB0000',
			contrastText: '#FFFFFF',
		}
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: "#403B2A",
            }
        },
    },

});
