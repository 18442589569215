import React,{useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RoomInput from './RoomInput';
import Help from './Help';
import Loading from './Loading';
import ImgFace from './ImgFace';
import Toast from './Toast';
import TopBar from "./TopBar";
import {UserContainer} from './UserContainer';
import {Inc,Room} from './Inc';
import Ajax from './Ajax';
import store from './store';
import {Tools} from './Tools';

interface Props {
    
}

const Main = (props:Props)=> {
    const [states,setStates] = useState({
		rooms:[] as Room[],
		oldRooms:[] as Room[],
		photoData: "",
		editCharaSetIndex: -1,
		isLoading:true,
		isRoomInput:false,
	})
	const [permitAdd,setPermitAdd] = useState(false);
	const [toast,setToast] = useState("");
	const userState = UserContainer.useContainer();

	const closeToast = ()=>{
		setToast("");
	}
    const openRoomInput = async ()=>{
		setStates({...states,isRoomInput:true});
	}
    const closeRoomInput = async ()=>{
		setStates({...states,isRoomInput:false});
	}
    const load = async ()=>{
    	try {
			//const rooms = await store.getListingRooms();
			//const rooms = await store.getAllRooms();
			//setStates({...states,rooms:rooms,isRoomInput:false,isLoading: false});

			const res = await Ajax.getAllRooms();
			//let rooms:Room[] = [];
			//for( let i=0; i<res.rooms.length; i++ ){
			//	rooms.push( {...res.rooms[i],Names:res.rooms[i].Names.split("\t")} );
			//}
			setStates({...states,rooms:res.rooms,isRoomInput:false,isLoading: false});
		} catch(error){
			console.log("load",error);
		}
	}
	const checkPermitAdd = ()=>{
		let permitAdd = true;
		if( states.rooms.length>9 ){
			permitAdd = false;
		} else if( userState.user ){
			for( let i=0; i<states.rooms.length; i++ ){
				if( states.rooms[i].UserId===userState.user.uid && states.rooms[i].Status!==Inc.ROOM_STATUS_CLOSED && states.rooms[i].Status!==Inc.ROOM_STATUS_CANCELED ){
					permitAdd = false;
				}
			}
		} else {
			permitAdd = false;
		}
		setPermitAdd(permitAdd);
	}
	const addRoom = async (roomName:string,charaSetId:string,intervalId:string)=>{
		if(!userState.user){
			return;
		}
		if(!permitAdd){
			return;
		}
		try {
			setStates({...states,isLoading:true})
			const room:Room = Inc.defaultRoom();
			room.Title = roomName;
			room.UserId = userState.user.uid;
			room.CharaSetId = charaSetId;
			room.IntervalId = intervalId;
			Ajax.setToken(userState.token);
			const res = await Ajax.addRoom(room);
			console.log(res);
			load()
			//setStates({...states,isRoomInput:false,isLoading:false})
		} catch(error){
			console.log("error",error);
		}
	}
	const resetCacheRoom = async (event: React.MouseEvent<HTMLElement>)=>{
        if( !event.currentTarget.dataset.id || !userState.user ){
            return;
		}
		Ajax.setToken(userState.token);
		await Ajax.resetCacheRoom(event.currentTarget.dataset.id,userState.user.uid);
		//console.log(res);
		setToast("リセット！");
	}

	useEffect( ()=>{
		const start = async ()=>{
			await load();
			//checkPermitAdd();
		}
        if( userState.ready ){
			start()
		}
	},[userState.ready]);

	useEffect( ()=>{
		checkPermitAdd();
	},[userState.user,states.rooms]);

    if( !userState.ready ){
        return (null);
    }

    return (
		<React.Fragment>
		<TopBar/>
        <div id="main" className="mediumRoot">
			<Paper className="paper">
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<div className="addRoom">
							<div className="guide">ハコブネを作ってヒトを募りましょう</div>
							<Button variant="contained" color="primary" onClick={openRoomInput} disabled={!permitAdd}>ハコブネ作成</Button>
							{userState && userState.user && userState.user.uid==="yBUSAZPGNSgeTq1zvXLusrm3CMe2" &&
							<Link to="/mychara" className="link"><Button variant="contained" color="primary">Myキャラセット表示</Button></Link>
							}
							<div className="hint">
								※作ったハコブネの削除はできません。<br/>
								※ハコブネの定員は６～９人です。<br/>
							</div>
						</div>
					</Grid>
				{states.rooms.map((room:Room,index:number)=>{
					const key = "room"+index;
					let to = "/play/" + room.Id;
					if( room.Status===Inc.ROOM_STATUS_CLOSED || room.Status===Inc.ROOM_STATUS_CANCELED ){
						to = "/view/" + room.Id;
					}
					return (
      					<Grid item xs={12} md={6} key={key}>
      						<Link to={to} className="link">
								<div className="topRoom">
									<div className="face"><ImgFace code={room.CharaSetId} charaId={room.TopCharaId}/></div>
									<div className="members">{room.NumMembers}人</div>
									<div className="text">
										<div className="title">{room.Title}</div>
										<div className="days">
											{room.Day===0 && room.Status===Inc.ROOM_STATUS_WAITING &&
											<span>プロローグ <FontAwesomeIcon icon="satellite-dish" className="nowplaying"/></span>
											}
											{room.Status===Inc.ROOM_STATUS_INSERVICE &&
											<span>{room.Day}日目 <FontAwesomeIcon icon="satellite-dish" className="nowplaying"/></span>
											}
											{room.Status===Inc.ROOM_STATUS_EPILOGUE &&
											<span>エピローグ <FontAwesomeIcon icon="satellite-dish" className="nowplaying"/></span>
											}
											{room.Status===Inc.ROOM_STATUS_CANCELED &&
											<span style={{color:"#999999"}}>定員割れ（終了）</span>
											}
											{room.Status===Inc.ROOM_STATUS_CLOSED &&
											<span style={{color:"#999999"}}>{room.Day}日目（終了）</span>
											}
											{room.Accepting && 
												<span className="accepting">受付中</span>
											}
										</div>
										<div className="nexttime">
											<span>次回更新</span>
											{room.NextTime !=="" ? (
											<span>{Tools.toSimpleDate(room.NextTime)}</span>
											):(
											<span>なし</span>
											)}
										</div>
										<div className="data">
											<span className="charaset">{room.CharaSetTitle}</span>
											｜
											<span className="interval">{Tools.getLabel(Inc.dayIntervals,room.IntervalId)}</span>
										</div>
									</div>
								</div>
      						</Link>
      					</Grid>
      				)
				})}
				</Grid>
				<div className="greeting">
					<FontAwesomeIcon icon="dove"/> <FontAwesomeIcon icon="dove"/> <FontAwesomeIcon icon="dove"/><br/>
					このハコブネシマイはパイロッタ版です。<br/>
					以下のいずれかによって終了します。<br/>
					<div className="reason">
					・10個のハコブネが終了する<br/>
					・10個のハコブネがいつまでたっても終了しない<br/>
					</div>
					version: {process.env.REACT_APP_VERSION}<br/>
					<br/>
					<a href="https://hakobune1.retro-ink.com/" style={{fontSize:"90%",color:"#000000"}}>旧バージョンはこちら</a><br/>
					<div className="copyright">
						<FontAwesomeIcon icon="copyright"/> レトロインク 2020<br/>
						<a href="https://goisagi.retro-ink.com" target="_blank" rel="noopener noreferrer">https://goisagi.retro-ink.com</a><br/>
						<br/>
						<FontAwesomeIcon icon="copyright"/> Mie NAKANISHI 2020<br/>
						<a href="http://www.joyrider.info" target="_blank" rel="noopener noreferrer">http://www.joyrider.info</a><br/>
					</div>
				</div>
			</Paper>
            <Loading open={states.isLoading}/>
			<Toast close={closeToast} mes={toast}/>
			<RoomInput isOpen={states.isRoomInput} add={addRoom} close={closeRoomInput}/>
			<Help/>
        </div>
		</React.Fragment>
    );
}

export default Main;
