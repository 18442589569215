import React from 'react';
import {Inc,Room,Talk} from './Inc';
import ItemSign from './ItemSign';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
}

const TalkTrap = (props:Props)=> {
	const items = props.room.GhostItems.split(",");
    return (
		<div className="sysTalk">
			<span className="type">見えざる手</span>
			{props.talk.Message !== "" ? (
				<span className="detail"><ItemSign itemId={props.talk.Message}/> {props.room.Names[props.talk.CharaId-1]}が <b>{items[parseInt(props.talk.Message)-1]}</b> を選択しました</span>
			):(
				<span className="detail">{props.room.Names[props.talk.CharaId-1]}が選択しました</span>
			)}
			<span className="time">{Tools.toTime(props.talk.Date)}</span>
		</div>
	);
}

export default TalkTrap;
